import { useTranslation } from "react-i18next";
import Text from "../../Components/Atoms/Text";
import Section from "../../Components/Atoms/Section";

const Description = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        pt="80px"
        pb="10px"
        margin="auto"
        pbMobile="0px"
        ptMobile="50px"
        widthMobile="80%"
        widthTablet="80%"
        width="60%"
      >
        <Text
          marginTop="10px"
          size="22px"
          sizeTablet="20px"
          sizeMobile="15px"
          align="left"
          color="#000000"
        >
          {t("About.Description.Text1")}
        </Text>
        <Text
          marginTop="20px"
          marginBottom="60px"
          size="22px"
          sizeTablet="20px"
          sizeMobile="15px"
          align="left"
          color="#000000"
        >
          {t("About.Description.Text2")}
        </Text>
      </Section>
    </>
  );
};

export default Description;
