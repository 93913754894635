import { useTranslation } from "react-i18next";
import Intro from "../../Components/Molecules/Intro";
import Services from "./Services";
import WhyUS from "./WhyUS/WhyUS";
import HomeIntro from "../../Assets/Home/HomeIntro.png";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={HomeIntro}
        title={t("Home.Intro.Title")}
        detail={t("Home.Intro.Detail")}
        buttonTitle={t("Home.Intro.ContactUs")}
        top="47%"
        tabletTop="53%"
        mobileTop="55%"
        border={false}
        sizeDesktop="55px"
        sizeTablet="30px"
        sizeMobile="17px"
        heightMobile="210px"
        buttonLink="/Contact"
      />
      <Services />
      <WhyUS />
    </>
  );
};

export default Home;
