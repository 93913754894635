import React from "react";
import Section from "../Atoms/Section";
import Text from "../Atoms/Text";
import HeroBox from "../Atoms/HeroBox";
import { Button, ButtonContainer, ButtonLabel } from "../Atoms/Button";
import Image from "../Atoms/Image";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import styled from "styled-components";
import NavLink from "../Atoms/NavLink";
import { device } from "../Atoms/Devices";

const Title = styled.h1`
  width: 100%;
  margin: auto;
  font-size: ${(props) => props.size || "inherit"};
  margin: ${(props) => props.margin || "auto"};
  text-transform: ${(props) => props.transform || "inherit"};
  font-weight: ${(props) => props.weight || "inherit"};
  text-align: ${(props) => props.align || "center"};
  color: ${(props) => props.color || props.theme.colors.white};
  text-decoration: ${(props) => (props.border ? "underline" : "none")};
  text-underline-offset: 10px;
  text-decoration-thickness: 2.5px;
  text-decoration-color: ${(props) => props.theme.colors.primary};
  padding-bottom: ${(props) => (props.border ? "6px" : 0)};
  @media ${device.tablet} {
    font-size: ${(props) => props.sizeTablet || props.size};
  }
  @media ${device.mobile} {
    font-size: ${(props) => props.sizeMobile || props.size};
    line-height: 1.4;
    padding-bottom: 0;
    text-decoration: none;
  }
`;

const Intro = ({
  top,
  mobileTop,
  tabletTop,
  align,
  backgroundImage,
  border,
  buttonTitle,
  color,
  detail,
  title,
  sizeDesktop,
  sizeMobile,
  sizeTablet,
  heightMobile,
  heightTablet,
  buttonLink,
}) => {
  const { t } = useTranslation();

  return (
    <Section header backgroundSize="cover" pt="80px">
      <Image
        src={backgroundImage}
        header
        width="100%"
        height="100%"
        heightMobile={heightMobile || "100%"}
        heightTablet={heightTablet || "100%"}
        alt={title}
      />
      <HeroBox top={top || "50%"} mobileTop={mobileTop} tabletTop={tabletTop}>
        <Title
          size={sizeDesktop || "85px"}
          sizeTablet={sizeTablet || "45px"}
          sizeMobile={sizeMobile || "30px"}
          color={color || "white"}
          border={border}
          weight="bolder"
          transform="uppercase"
          align={align || "center"}
        >
          {t(title)}
        </Title>
        {detail && (
          <Fragment>
            <Text
              size="25px"
              sizeTablet="17px"
              sizeMobile="13px"
              maxWidth="70%"
              margin="auto"
              marginTop="25px"
              marginTopTablet="15px"
              marginTopMobile="5px"
            >
              {detail}
            </Text>
            <ButtonContainer>
              <Button header paddingMobile="3px 10px" marginTopMobile="15px">
                <NavLink to={buttonLink}>
                  <ButtonLabel color="white" fontSizeMobile="13px">
                    {t(buttonTitle)}
                  </ButtonLabel>
                </NavLink>
              </Button>
            </ButtonContainer>
          </Fragment>
        )}
      </HeroBox>
    </Section>
  );
};

export default Intro;
