import styled from "styled-components";
import { device } from "./Devices";

const Column = styled.div`
  width: ${(props) => props.width};
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  @media ${device.tablet} {
    width: ${(props) => props.widthTablet || "96%"};
    margin: ${(props) => (props.about ? "0" : "auto")};
    margin-bottom: ${(props) => props.marginBottomTablet || "30px"};
    margin-top: ${(props) => props.marginTopTablet || "0px"};
  }
  @media ${device.mobile} {
    width: ${(props) => props.widthMobile || "100%"};
    margin: ${(props) => (props.noMargin ? 0 : "auto")};
    margin-bottom: ${(props) => props.marginBottomMobile || "20px"};
    margin-top: ${(props) => props.marginTopMobile || 0};
  }
`;

export default Column;
