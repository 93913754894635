import { useTranslation } from "react-i18next";

import Profilbild from "../../Assets/About/Profilbild.png";
import Education from "../../Assets/About/Education.png";
import Certifications from "../../Assets/About/Certifications.png";

import Section from "../../Components/Atoms/Section";
import Text from "../../Components/Atoms/Text";
import Row from "../../Components/Atoms/Row";
import Column from "../../Components/Atoms/Column";
import Image from "../../Components/Atoms/Image";

const Owner = () => {
  const { t } = useTranslation();

  return (
    <Section
      pt="20px"
      pb="100px"
      margin="auto"
      pbMobile="50px"
      ptMobile="0px"
      widthMobile="80%"
      widthTablet="80%"
      width="60%"
    >
      <Text
        marginTop="20px"
        marginBottom="30px"
        marginLeft="0px"
        size="18px"
        sizeTablet="16px"
        sizeMobile="16px"
        align="left"
        color="#000000"
        display="inline-block"
        weight="600"
      >
        {t("About.Owner.Title")}
      </Text>
      <Row isRow>
        <Column
          width="20%"
          widthTablet="33%"
          widthMobile="60%"
          marginTopTablet="10px"
        >
          <Image width="90%" src={Profilbild} />
        </Column>
        <Column width="75%" widthTablet="64%">
          <Text
            marginTop="10px"
            size="18px"
            sizeTablet="16px"
            sizeMobile="16px"
            align="left"
            color="#000000"
          >
            {t("About.Owner.Text1")}
          </Text>
        </Column>
      </Row>
      <Row marginTop="20px">
        <Column width="36%">
          <Text
            marginTop="20px"
            marginBottom="30px"
            size="18px"
            sizeTablet="16px"
            sizeMobile="16px"
            align="left"
            color="#000000"
            display="inline-block"
            width="30%"
            weight="600"
          >
            {t("About.Owner.Education")}
          </Text>
          <Column
            width="100%"
            widthTablet="65%"
            widthMobile="100%"
            marginTop="10px"
          >
            <Image src={Education} />
          </Column>
        </Column>
        <Column width="58%">
          <Row>
            <Text
              marginTop="20px"
              marginBottom="30px"
              marginLeft="0px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
              display="inline-block"
              width="30%"
              weight="600"
            >
              {t("About.Owner.Certification")}
            </Text>
          </Row>
          <Row>
            <Column width="100%">
              <Image src={Certifications} />
            </Column>
          </Row>
        </Column>
      </Row>
    </Section>
  );
};

export default Owner;
