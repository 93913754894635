import { useTranslation } from "react-i18next";

import HeaderImage from "../Assets/Legal/Header.png";
import Intro from "../Components/Molecules/Intro";
import Section from "../Components/Atoms/Section";
import Text from "../Components/Atoms/Text";

const Legal = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={HeaderImage}
        title={t("Legal.Title")}
        top="40%"
        mobileTop="55%"
        sizeDesktop="55px"
        sizeTablet="40px"
        sizeMobile="25px"
        border
      />
      <Section
        pt="110px"
        pb="110px"
        margin="auto"
        pbMobile="50px"
        ptMobile="50px"
        widthMobile="80%"
        widthTablet="80%"
        width="60%"
      >
        <Text
          marginBottom="5px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("Legal.Sec1Title")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec1Text1")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec1Text2")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec1Text3")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec1Text4")}
        </Text>
        <Text
          marginTop="60px"
          marginBottom="5px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("Legal.Sec2Title")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec2Text1")}
        </Text>
        <Text
          marginTop="60px"
          marginBottom="5px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("Legal.Sec3Title")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec3Text1")}
        </Text>
        <Text
          marginTop="60px"
          marginBottom="5px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("Legal.Sec4Title")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec4Text1")}
        </Text>
        <Text
          marginTop="60px"
          marginBottom="5px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("Legal.Sec5Title")}
        </Text>
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          align="left"
          color="#000000"
        >
          {t("Legal.Sec5Text1")}
        </Text>
      </Section>
    </>
  );
};

export default Legal;
