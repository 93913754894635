import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import WhyUsImage1 from "../../../Assets/Home/WhyUsImage1.png";
import WhyUsImage2 from "../../../Assets/Home/WhyUsImage2.png";
import WhyUsImage3 from "../../../Assets/Home/WhyUsImage3.png";
import WhyUsImage4 from "../../../Assets/Home/WhyUsImage4.png";
import WhyUsCounter from "../../../Assets/Home/WhyUsCounter.png";

import Text from "../../../Components/Atoms/Text";
import Image from "../../../Components/Atoms/Image";
import { device } from "../../../Components/Atoms/Devices";
import WhyUsContent from "./WhyUsContent";
import Title from "../../../Components/Atoms/Title";
import WhyUsBackground from "../../../Assets/Home/WhyUsBackground.png";
import Section from "../../../Components/Atoms/Section";

const HomeContainer = styled.div`
  padding: 0.5rem calc((100vw - 1000px) / 2);
`;

const InnerContainer = styled.div`
  padding-top: 100px;
  @media ${device.tablet} {
    padding-top: 140px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.tablet} {
    width: 80%;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export const CounterContainer = styled.div`
  position: absolute;
  top: 7.5%;
  left: 45%;
  display: block;
  @media ${device.tablet} {
    display: ${(props) => (props.responsive ? "block" : "none")};
    left: -7%;
    top: 6%;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

const WhyUS = () => {
  const { t } = useTranslation();
  return (
    <Section backgroundImage={WhyUsBackground}>
      <HomeContainer>
        <Container>
          <InnerContainer>
            <Title color="white" marginTop="20px">
              {t("Home.WhyChooseUs.Title")}
            </Title>
            <Text
              size="18px"
              sizeTablet="16px"
              sizeMobile="13px"
              marginTop="30px"
              marginBottom="55px"
            >
              {t("Home.WhyChooseUs.Subtitle")}
            </Text>
            <ContentContainer>
              <CounterContainer>
                <Image src={WhyUsCounter} height="1080px" width="85px" />
              </CounterContainer>
              <WhyUsContent
                title={t("Home.WhyChooseUs.Phase1Title")}
                description={t("Home.WhyChooseUs.Phase1Content")}
                image={WhyUsImage1}
                direction="row"
              />
              <WhyUsContent
                title={t("Home.WhyChooseUs.Phase2Title")}
                description={t("Home.WhyChooseUs.Phase2Content")}
                image={WhyUsImage2}
                direction="row-reverse"
                counter
              />
              <WhyUsContent
                title={t("Home.WhyChooseUs.Phase3Title")}
                description={t("Home.WhyChooseUs.Phase3Content")}
                image={WhyUsImage3}
                direction="row"
              />
              <WhyUsContent
                title={t("Home.WhyChooseUs.Phase4Title")}
                description={t("Home.WhyChooseUs.Phase4Content")}
                image={WhyUsImage4}
                direction="row-reverse"
                paddingBottom="100px"
              />
            </ContentContainer>
          </InnerContainer>
        </Container>
      </HomeContainer>
    </Section>
  );
};

export default WhyUS;
