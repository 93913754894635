import styled from "styled-components";
import { device } from "../Components/Atoms/Devices";
import NavMenu from "./NavMenu";
import BurgerMenu from "./BurgerMenu";
import Logo from "../Assets/Logo.png";
import NavLink from "../Components/Organisms/Navigation/NavMenu/NavLink";

const HeaderContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100vw;
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
`;

const LogoContainer = styled.div`
  margin-top: 2px;
  margin-left: 40px;
  & > * {
    cursor: pointer;
  }
  @media ${device.tablet} {
    margin-left: 0px;
  }
`;

const LogoImg = styled.img`
  height: 60px;
`;

const StyledBurgerMenu = styled(BurgerMenu)`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

const StyledNavMenu = styled(NavMenu)`
  margin-right: 80px;
  @media ${device.mobile} {
    display: none;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <NavContainer>
        <NavLink to="/">
          <LogoContainer>
            <LogoImg src={Logo} alt="Logo" />
          </LogoContainer>
        </NavLink>
        <StyledBurgerMenu />
        <StyledNavMenu />
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;

//<LogoImg src={Logo} />
