import styled from "styled-components";
import { device } from "./Devices";

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "space-between"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  align-items: ${(props) => props.alignItems || "flex-start"};
  flex-wrap: wrap;
  margin-top: ${(props) => props.marginTop || "inherit"};
  padding-bottom: ${(props) => props.paddingBottom || "0"};
  @media ${device.tablet} {
    flex-direction: ${(props) => (props.isRow ? "row" : "column")};
    margin: auto;
    align-self: center;
    padding-bottom: ${(props) => props.pbTablet || "0"};
  }
  @media ${device.mobile} {
    flex-direction: ${(props) => (props.isRowOnMobile ? "row" : "column")};
    margin-right: ${(props) => props.marginRightMobile || "auto"};
  }
`;

export default Row;
