import styled from "styled-components";
import { device } from "./Devices";

const Title = styled.p`
  font-size: 36px;
  font-style: ${(props) => props.fontStyle || "normal"};
  margin: auto;
  margin-bottom: ${(props) => props.marginBottom || "60px"};
  margin-top: ${(props) => props.marginTop || "auto"};
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  display: inherit;
  color: ${(props) => props.color || props.theme.colors.black};
  width: fit-content;
  text-decoration: ${(props) => (props.border ? "underline" : "none")};
  text-underline-offset: 10px;
  text-decoration-thickness: 2.5px;
  text-decoration-color: ${(props) => props.theme.colors.primary};
  max-width: 100%;
  padding-bottom: ${(props) => (props.border ? "6px" : 0)};
  vertical-align: top;
  line-height: 1.4;
  @media ${device.tablet} {
    font-size: 25px;
    padding: 0;
    margin-bottom: 30px;
    max-width: 100%;
  }
  @media ${device.mobile} {
    font-size: 17px;
    margin-bottom: 30px;
    line-height: 2;
  }
`;

export default Title;
