import styled from "styled-components";
import { device } from "./Devices";

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  background-color: #12a89e;
  color: white;
  border: 0;
  outline: none;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || "40px"};
  padding: 10px 5px;
  @media ${device.mobile} {
    margin-top: ${(props) => props.marginTopMobile || "40px"};
    padding: ${(props) => props.paddingMobile || " 10px 30px"};
  }
`;

export const ButtonLabel = styled.p`
  font-size: ${(props) => props.fontSize || "16px"};
  margin: 0;
  color: ${(props) => props.color || props.theme.colors.primary};
  font-weight: ${(props) => props.fontWeight || "normal"};
  @media ${device.tablet} {
    font-size: ${(props) => props.fontSizeTablet || "15px"};
  }
  @media ${device.mobile} {
    font-size: ${(props) => props.fontSizeMobile || "15px"};
  }
`;

export const ButtonWithIcon = styled.button`
  display: flex;
  flex-direction: row;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50px;
  width: fit-content;
  padding: 12px 20px;
  @media ${device.tablet} {
    padding: 10px 15px;
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  padding: 5px 0px;
`;
