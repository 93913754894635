import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Column from "../../Components/Atoms/Column";
import Row from "../../Components/Atoms/Row";

import Form from "../../Components/Organisms/Form/Form";
import FormInput from "../../Components/Organisms/Form/FormInput";
import Text from "../../Components/Atoms/Text";
import Checkbox from "../../Components/Molecules/Checkbox";
import RadioButton from "../../Components/Molecules/RadioButton";
import Section from "../../Components/Atoms/Section";

const ContactForm = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    name: "",
    contactPerson: "",
    email: "",
    phone: "",
    message: "",
    appDevelopment: false,
    digitalWorkplace: false,
    itConsulting: false,
    contact: "",
  });

  const [showError, setShowError] = useState(false);

  const checkFieldError = (prop) => {
    return showError && form[prop] === "";
  };

  const handleFormChange = (name, value) => {
    setShowError(false);
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = (id) => {
    form[id]
      ? setForm({ ...form, [id]: false })
      : setForm({ ...form, [id]: true });
  };

  const handleRadioChange = (id) => {
    setForm({ ...form, contact: id });
  };

  const sendButtonText = (
    <Text align="left" size="15px" transform="capitalize">
      {t("ContactPage.SendNow")}
    </Text>
  );

  const successMessage = (
    <>
      <Text
        align="center"
        size="17px"
        color={(props) => props.theme.colors.black}
        marginTop="100px"
      >
        {t("ContactPage.SuccessMessage1")}
      </Text>
      <Text
        align="center"
        size="17px"
        color={(props) => props.theme.colors.black}
        marginTop="10px"
        marginBottom="100px"
      >
        {t("ContactPage.SuccessMessage2")}
      </Text>
    </>
  );

  const errorMessage = (
    <>
      <Text
        align="center"
        size="17px"
        color={(props) => props.theme.colors.red}
        marginTop="100px"
      >
        {t("ContactPage.ErrorMessage1")}
      </Text>
      <Text
        align="center"
        size="17px"
        color={(props) => props.theme.colors.red}
        marginTop="10px"
        marginBottom="100px"
      >
        {t("ContactPage.ErrorMessage2")}
      </Text>
    </>
  );

  return (
    <Section
      pt="70px"
      pb="70px"
      margin="auto"
      pbMobile="50px"
      ptMobile="50px"
      widthMobile="80%"
      widthTablet="80%"
      width="60%"
    >
      <Form
        sendButtonText={sendButtonText}
        successMessage={successMessage}
        errorMessage={errorMessage}
        form={form}
        setShowError={setShowError}
      >
        <Row marginTop="10px" alignItems="flex-start">
          <Column width="45%" widthTablet="90%" widthMobile="90%">
            <FormInput
              label={t("ContactPage.Company")}
              type="text"
              name="name"
              marginBottom="20px"
              value={form["name"]}
              setValue={handleFormChange}
              showError={checkFieldError("name")}
            />
            <FormInput
              label={t("ContactPage.ContactPerson")}
              type="text"
              name="contactPerson"
              marginBottom="20px"
              value={form["contactPerson"]}
              setValue={handleFormChange}
              showError={checkFieldError("contactPerson")}
            />
            <Text
              size="16px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
              marginBottom="15px"
              marginTop="40px"
              weight={600}
            >
              {t("ContactPage.ContactOption")}
            </Text>
            <RadioButton
              id="option1"
              name="contactOption"
              label={t("ContactPage.ContactOption1")}
              checked={form["contact"] === "option1"}
              onChange={handleRadioChange}
            />
            {form["contact"] === "option1" && (
              <FormInput
                label={t("ContactPage.EMail")}
                type="email"
                name="email"
                marginBottom="20px"
                marginTop="20px"
                value={form["email"]}
                setValue={handleFormChange}
                showError={checkFieldError("email")}
              />
            )}
            <RadioButton
              id="option2"
              name="contactOption"
              label={t("ContactPage.ContactOption2")}
              checked={form["contact"] === "option2"}
              onChange={handleRadioChange}
            />
            {form["contact"] === "option2" && (
              <FormInput
                label={t("ContactPage.Phone")}
                placeholder={t("ContactPage.PhonePlaceholder")}
                type="tel"
                name="phone"
                marginBottom="20px"
                marginTop="20px"
                value={form["phone"]}
                setValue={handleFormChange}
                showError={checkFieldError("phone")}
                pattern="[0-9]"
              />
            )}
          </Column>
          <Column width="45%" widthTablet="90%" widthMobile="90%">
            <Text
              size="16px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
              marginBottom="15px"
              weight={600}
            >
              {t("ContactPage.ServiceOption")}
            </Text>
            <Checkbox
              id="appDevelopment"
              name="appDevelopment"
              label={t("Navigation.AppDevelopment")}
              value={form["appDevelopment"]}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              id="digitalWorkplace"
              name="digitalWorkplace"
              label={t("Navigation.DigitalWorkplace")}
              value={form["digitalWorkplace"]}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              id="itConsulting"
              name="itConsulting"
              label={t("Navigation.ITConsulting")}
              value={form["itConsulting"]}
              onChange={handleCheckboxChange}
            />
            <FormInput
              label={t("ContactPage.Message")}
              marginTop="40px"
              marginBottom="30px"
              name="message"
              type="textarea"
              multiline
              value={form["message"]}
              setValue={handleFormChange}
            />
          </Column>
        </Row>
      </Form>
    </Section>
  );
};

export default ContactForm;
