import React from "react";
import styled from "styled-components";
import Text from "../../Atoms/Text";

const InputField = styled.input`
  border: 0;
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.showError
      ? props.theme.colors.red
      : props.theme.colors.inputBorderColor};
  padding: 3px;
  padding-bottom: 10px;
  font-size: 16px;
  display: block;
  color: ${(props) => props.theme.colors.black};
  margin-top: "0px";
  margin-bottom: ${(props) => props.marginBottom || "10px"};
  width: ${(props) => props.width || "100%"};
  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  border: 0;
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.showError
      ? props.theme.colors.red
      : props.theme.colors.inputBorderColor};
  padding: 3px;
  display: block;
  font-size: 16px;
  resize: none;
  margin-top: "0px";
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  width: ${(props) => props.width || "100%"};
  &:focus {
    outline: none;
  }
`;

const FormInput = (props) => {
  return (
    <div>
      {props.label && (
        <Text
          size="16px"
          sizeTablet="16px"
          sizeMobile="16px"
          align="left"
          color="#000000"
          marginTop={props.marginTop ? props.marginTop : "0px"}
          weight={500}
        >
          {props.label}
        </Text>
      )}
      {!props.multiline ? (
        <InputField
          onChange={(e) => props.setValue(props.name, e.target.value)}
          {...props}
        />
      ) : (
        <TextArea
          onChange={(e) => props.setValue(props.name, e.target.value)}
          rows={props.rows || 4}
          cols={props.cols || 4}
          {...props}
        ></TextArea>
      )}
    </div>
  );
};

export default FormInput;
