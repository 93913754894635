import React from "react";
import styled from "styled-components";
import { device } from "../Atoms/Devices";

const StyledList = styled.ul`
  margin-left: ${(props) => props.left || "10px"};
  list-style-type: ${(props) => props.listStyleType || "disc"};
  @media ${device.mobile} {
    padding-inline-start: 20px;
  }
`;
const StyledListItem = styled.li`
  font-size: 18px;
  line-height: ${(props) => props.lineHight || "33px"};
  padding-bottom: ${(props) => props.paddingBottom || "0px"};
  &::marker {
    font-size: 25px;
    color: ${(props) => props.theme.colors.primary};
  }
  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
    padding-bottom: ${(props) => props.paddingBottomTablet || "0px"};
  }
  @media ${device.mobile} {
    font-size: ${(props) => props.sizeMobile || "13px"};
    line-height: 1.3;
    &::marker {
      font-size: 20px;
    }
  }
`;

const List = (props) => {
  return (
    <StyledList left={props.left} listStyleType={props.listStyleType}>
      {props.list?.map((l, i) => (
        <StyledListItem
          lineHight={props.lineHight}
          paddingBottom={props.paddingBottom}
          paddingBottomTablet={props.paddingBottomTablet}
          sizeMobile={props.sizeMobile}
          key={i}
        >
          {l}
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default List;
