import { useTranslation } from "react-i18next";

import HeaderImage from "../../Assets/About/Header.png";
import Intro from "../../Components/Molecules/Intro";
import Owner from "./Owner";
import Description from "./Description";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={HeaderImage}
        title={t("About.Title")}
        top="40%"
        mobileTop="62%"
        tabletTop="50%"
        sizeDesktop="65px"
        sizeTablet="40px"
        sizeMobile="25px"
        border
      />
      <Description />
      <Owner />
    </>
  );
};

export default About;
