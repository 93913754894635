import { useTranslation } from "react-i18next";
import Section from "../../Components/Atoms/Section";
import Text from "../../Components/Atoms/Text";
import Row from "../../Components/Atoms/Row";
import Column from "../../Components/Atoms/Column";
import Image from "../../Components/Atoms/Image";
import Divider from "../../Components/Atoms/Divider";
import List from "../../Components/Molecules/List";
import Carousel from "../../Components/Organisms/Carousel/Carousel";
import Title from "../../Components/Atoms/Title";

import NetworkMap from "../../Assets/AppDevelopment/network-map.png";
import Design from "../../Assets/AppDevelopment/Design.png";
import Agile from "../../Assets/AppDevelopment/Agile.png";

const Competences = () => {
  const { t } = useTranslation();

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
    1440: { items: 1 },
    1920: { items: 1 },
  };

  const items = [
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="22px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("AppDevelopment.Competences.Design.Title")}
      </Text>
      <Divider width="97%" height="2px" />
      <Column
        width="40%"
        marginTop="20px"
        marginLeft="10px"
        widthTablet="70%"
        widthMobile="90%"
        marginBottomTablet="20px"
        marginBottomMobile="10px"
        marginTopTablet="10px"
        marginTopMobile="5px"
      >
        <Image src={Design} />
      </Column>
      <Column width="50%" marginTop="80px">
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Design.Text1")}
        </Text>
        <Text
          marginTop="20px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Design.Text2")}
        </Text>
        <Text
          marginTop="35px"
          tabletBottomMargin="10px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Design.Concepts")}
        </Text>
        <List
          list={[
            t("AppDevelopment.Competences.Design.List1"),
            t("AppDevelopment.Competences.Design.List2"),
            t("AppDevelopment.Competences.Design.List3"),
          ]}
          left="0px"
        />
      </Column>
    </Row>,
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="22px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("AppDevelopment.Competences.Team.Title")}
      </Text>
      <Divider width="97%" height="2px" />
      <Column
        width="45%"
        marginTop="40px"
        widthTablet="70%"
        widthMobile="90%"
        marginLeft="10px"
        marginBottomTablet="20px"
        marginBottomMobile="10px"
        marginTopTablet="20px"
        marginTopMobile="5px"
      >
        <Image src={NetworkMap} />
      </Column>
      <Column width="50%" marginTop="30px">
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Team.Text1")}
        </Text>
        <Text
          marginTop="20px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Team.Text2")}
        </Text>
        <Text
          marginTop="35px"
          tabletBottomMargin="10px"
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
        >
          {t("AppDevelopment.Competences.Team.Advantage")}
        </Text>
        <List
          list={[
            t("AppDevelopment.Competences.Team.List1"),
            t("AppDevelopment.Competences.Team.List2"),
            t("AppDevelopment.Competences.Team.List3"),
          ]}
          left="0px"
        />
      </Column>
    </Row>,
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="22px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("AppDevelopment.Competences.Agile.Title")}
      </Text>
      <Divider width="97%" height="2px" />
      <Column
        width="40%"
        marginTop="40px"
        marginLeft="10px"
        widthTablet="70%"
        widthMobile="90%"
        marginBottomTablet="20px"
        marginTopTablet="10px"
      >
        <Image src={Agile} />
      </Column>
      <Column width="50%" marginTop="40px">
        <Text
          size="18px"
          sizeTablet="15px"
          sizeMobile="13px"
          color="#000000"
          align="left"
          marginBottom="20px"
        >
          {t("AppDevelopment.Competences.Agile.Text1")}
        </Text>
        <List
          list={[
            t("AppDevelopment.Competences.Agile.List1"),
            t("AppDevelopment.Competences.Agile.List2"),
            t("AppDevelopment.Competences.Agile.List3"),
          ]}
          left="0px"
          lineHight="normal"
          paddingBottom="20px"
          paddingBottomTablet="5px"
          listStyleType="square"
        />
      </Column>
    </Row>,
  ];

  return (
    <Section
      pt="150px"
      pb="100px"
      margin="auto"
      pbMobile="50px"
      ptMobile="100px"
      widthMobile="90%"
    >
      <Title>{t("AppDevelopment.Competences.Title")}</Title>
      <Section
        width="80%"
        widthTablet="80%"
        margin="auto"
        pt="30px"
        pb="100px"
        ptMobile="0px"
      >
        <Carousel items={items} responsive={responsive} />
      </Section>
    </Section>
  );
};

export default Competences;
