import React from "react";
import { useTranslation } from "react-i18next";

import NavContainer from "../Components/Organisms/Navigation/NavMenu/NavContainer";
import NavLink from "../Components/Organisms/Navigation/NavMenu/NavLink";
import NavDropdown from "../Components/Organisms/Navigation/NavMenu/NavDropdown/NavDropdown";
import NavDropdownLink from "../Components/Organisms/Navigation/NavMenu/NavDropdown/NavDropdownLink";
import NavP from "../Components/Organisms/Navigation/NavMenu/NavP";

const checkHomeActive = () => {
  const pathname = window.location.pathname;
  return pathname === "/";
};

const checkServicesActive = () => {
  const pathname = window.location.pathname;
  return (
    pathname === "/AppDevelopment" ||
    pathname === "/CloudTransformation" ||
    pathname === "/DigitalWorkplace" ||
    pathname === "/ITConsulting"
  );
};

const NavMenu = ({ className }) => {
  const { t } = useTranslation();

  return (
    <NavContainer className={className}>
      <NavLink to="/" isActive={checkHomeActive}>
        <NavP>{t("Navigation.Home")}</NavP>
      </NavLink>
      <NavDropdown
        label={t("Navigation.Services")}
        isActive={checkServicesActive}
      >
        <NavDropdownLink to="/AppDevelopment">
          <NavP>{t("Navigation.AppDevelopment")}</NavP>
        </NavDropdownLink>
        <NavDropdownLink to="/DigitalWorkplace">
          <NavP>{t("Navigation.DigitalWorkplace")}</NavP>
        </NavDropdownLink>
        <NavDropdownLink to="/ITConsulting">
          <NavP>{t("Navigation.ITConsulting")}</NavP>
        </NavDropdownLink>
      </NavDropdown>
      <NavLink to="/about">
        <NavP>{t("Navigation.About")}</NavP>
      </NavLink>
      <NavLink to="/contact">
        <NavP>{t("Navigation.Contact")}</NavP>
      </NavLink>
    </NavContainer>
  );
};

export default NavMenu;
