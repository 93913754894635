import React, { useState } from "react";

import { SendButton, ArrowRight, ButtonContent, Spinner } from "./SendButton";
import { callCloudFunctionWithAppCheck } from "../../../firebaseProvider";

const sendingStatus = {
  notSent: 0,
  pending: 1,
  sentWithSuccess: 2,
  sentWithError: 3,
};

const Form = (props) => {
  const [formSendingStatus, setFormSendingStatus] = useState(
    sendingStatus.notSent
  );

  const handleSubmit = () => {
    if (
      props.form.name === "" ||
      props.form.contactPerson === "" ||
      (props.form.contact === "option1" && props.form.email === "") ||
      (props.form.contact === "option2" && props.form.phone === "")
    ) {
      props.setShowError(true);
    } else {
      setFormSendingStatus(sendingStatus.pending);
      callCloudFunctionWithAppCheck("sendEmail", props.form)
        .then((_) => setFormSendingStatus(sendingStatus.sentWithSuccess))
        .catch((_) => setFormSendingStatus(sendingStatus.sentWithError));
    }
  };

  const getFormContent = () => {
    switch (formSendingStatus) {
      case 1:
        return (
          <>
            {props.children}
            <SendButton onClick={handleSubmit}>
              <ButtonContent>
                <Spinner />
              </ButtonContent>
            </SendButton>
          </>
        );
      case 2:
        return props.successMessage;
      case 3:
        return props.errorMessage;
      default:
        return (
          <>
            {props.children}
            <SendButton onClick={handleSubmit}>
              <ButtonContent>
                {props.sendButtonText}
                <ArrowRight />
              </ButtonContent>
            </SendButton>
          </>
        );
    }
  };

  return (
    <div>
      {props.title && props.title}
      {getFormContent()}
    </div>
  );
};

export default Form;
