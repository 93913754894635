import Section from "../Components/Atoms/Section";
import Text from "../Components/Atoms/Text";

const PageNotFound = () => {
  return (
    <>
      <Section pt="20%" pb="15%">
        <Text size="30px" color="#000000" weight="bolder">
          PAGE NOT FOUND
        </Text>
      </Section>
    </>
  );
};

export default PageNotFound;
