import React from "react";
import Row from "../../../Components/Atoms/Row";
import Column from "../../../Components/Atoms/Column";
import Text from "../../../Components/Atoms/Text";
import Image from "../../../Components/Atoms/Image";

const WhyUsContent = (props) => {
  return (
    <div>
      <Row
        marginTop="10px"
        justify="flex-start"
        paddingBottom={props.paddingBottom || 0}
        alignItems="center"
        flexDirection={props.direction}
        pbTablet="100px"
      >
        <Column
          width={"40%"}
          widthTablet="80%"
          widthMobile="80%"
          marginBottomTablet="0px"
          marginBottomMobile="5px"
        >
          <Image
            marginLeft={props.marginLeft || 0}
            src={props.image}
            width={props.imageSize || "90%"}
            widthTablet={"60%"}
          />
        </Column>
        <Column width="20%"></Column>
        <Column width={"40%"} widthTablet="80%">
          <Text
            color={props.reverse ? "#000" : "#FFF"}
            align={props.isTablet ? "center" : "left"}
            size="21px"
            sizeTablet="18px"
            sizeMobile="15px"
            weight="bold"
          >
            {props.title}
          </Text>
          <Text
            color={props.reverse ? "#000" : "#FFF"}
            align={props.isTablet ? "center" : "left"}
            sizeTablet="15px"
            sizeMobile="13px"
            maxWidth="100%"
            marginTop="10px"
            size="18px"
          >
            {props.description}
          </Text>
        </Column>
      </Row>
    </div>
  );
};

export default WhyUsContent;
