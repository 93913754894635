const firebaseConfig = {
  apiKey: "AIzaSyAAWB_dCWgPzj2zz_LLABsVTSCRyXk8pCw",
  authDomain: "tecfoxwebseite.firebaseapp.com",
  projectId: "tecfoxwebseite",
  storageBucket: "gs://tecfoxwebseite.appspot.com",
  messagingSenderId: "801264782013",
  appId: "1:801264782013:web:1b11973392959a561685d4",
  measurementId: "G-RBRD2ZCGER",
};

export default firebaseConfig;
