import styled from "styled-components";
import { device } from "./Devices";

const Image = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  border-radius: ${(props) => props.borderRadius || "auto"};
  border: ${(props) => props.border || "none"};
  margin-left: ${(props) => props.marginLeft || "inherit"};
  margin-top: ${(props) => props.marginTop || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "inherit"};
  transition: 1s all;

  @media ${device.tablet} {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: ${(props) => (props.logo ? "100%" : props.widthTablet)};
    height: ${(props) =>
      props.logo
        ? props.logoTabletHeight || "50px"
        : props.header
        ? props.heightTablet || "35vh"
        : "100%"};
  }

  @media ${device.mobile} {
    width: ${(props) => (props.logo ? "100px" : "100%")};
    height: ${(props) =>
      props.logo
        ? props.logoMobileHeight || "50px"
        : props.header
        ? props.heightMobile || "35vh"
        : "100%"};
  }
`;

export const Image2 = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  border-radius: ${(props) => props.borderRadius || "auto"};
  border: ${(props) => props.border || "none"};
  margin-left: ${(props) => props.marginLeft || "inherit"};
  margin-top: ${(props) => props.marginTop || "inherit"};
  transition: 1s all;

  @media screen and (max-width: 568px) {
    width: ${(props) => (props.logo ? "100px" : "100%")};
    height: ${(props) =>
      props.logo
        ? props.logoMobileHeight || "50px"
        : props.header
        ? props.heightMobile || "35vh"
        : "100%"};
  }
`;

export default Image;
