import React from "react";
import HeaderImage from "../../Assets/ITConsulting/Header.png";
import { useTranslation } from "react-i18next";
import Intro from "../../Components/Molecules/Intro";
import Description from "./Description";

const ITConsulting = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={HeaderImage}
        title={t("ITConsulting.Title")}
        sizeDesktop="55px"
        sizeTablet="30px"
        sizeMobile="17px"
        mobileTop="60%"
        border
      />
      <Description />
    </>
  );
};

export default ITConsulting;
