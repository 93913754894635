import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./Utils/scrollToTop";
import "./App.css";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import AppDevelopment from "./Pages/AppDevelopment/AppDevelopment";
import DigitalWorkplace from "./Pages/DigitalWorkplace/DigitalWorkplace";
import ITConsulting from "./Pages/ITConsulting/ITConsulting";
import Contact from "./Pages/Contact/Contact";
import PageNotFound from "./Pages/PageNotFound";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Legal from "./Pages/Legal";

const theme = {
  colors: {
    primary: "#12a89e",
    secundary: "#f1f1f1",
    navFontColor: "#000000",
    navDropdownFontColor: "#000000",
    navChevronColor: "#787878",
    navDropdownBGColor: "#f9f9f9",
    burgerFontColor: "#000000",
    burgerDropdownFontColor: "#000000",
    burgerChevronColor: "#787878",
    burgerBGColor: "#f9f9f9",
    burgerDropdownBGColor: "#ededed",
    lngFontColor: "#ffffff",
    lngDropdownFontColor: "#000000",
    lngChevronColor: "#ffffff",
    lngDropdownBGColor: "#f9f9f9",
    inputBorderColor: "#c9c9c9",
    black: "#000000",
    grey: "#787878",
    middlegrey: "#cccccc",
    lightgrey: "#eeeeee",
    white: "#ffffff",
    red: "#f22e2e",
  },
  typography: {
    fontFamily: "Montserrat",
  },
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.typography.fontFamily};
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
  }
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/About" component={About} />
          <Route path="/AppDevelopment" component={AppDevelopment} />
          <Route path="/DigitalWorkplace" component={DigitalWorkplace} />
          <Route path="/ITConsulting" component={ITConsulting} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Legal" component={Legal} />
          <Route path="/" component={PageNotFound} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
