import React from "react";
import Section from "../../Components/Atoms/Section";
import Text from "../../Components/Atoms/Text";
import Row from "../../Components/Atoms/Row";
import Column from "../../Components/Atoms/Column";
import Image from "../../Components/Atoms/Image";
import styled from "styled-components";
import BackgroundImage from "../../Assets/DigitalWorkplace/QuoteBackground.jpg";
import VivekKundra from "../../Assets/DigitalWorkplace/VivekKundra.jpg";
import { device } from "../../Components/Atoms/Devices";

import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 60%;
  margin: auto;
  @media ${device.tablet} {
    width: 90%;
  }
`;

const OverLay = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Wrapper = styled.div`
  padding: 50px 0;
  z-index: 3;
`;

const Quote = () => {
  const { t } = useTranslation();

  return (
    <Section backgroundImage={BackgroundImage} backgroundSize="cover">
      <OverLay>
        <Container>
          <Wrapper>
            <Row isRow>
              <Column width="75%" widthTablet="70%" widthMobile="100%">
                <Text
                  marginTop="20px"
                  size="16px"
                  sizeTablet="14px"
                  sizeMobile="12px"
                  marginBottom="15px"
                  align="center"
                  fontStyle="italic"
                  fontFamily="Arial"
                  weight={400}
                >
                  {t("DigitalWorkplace.Suggestions.Quote.Paragraph")}
                </Text>
                <Text
                  size="16px"
                  sizeTablet="14px"
                  sizeMobile="12px"
                  marginBottom="10px"
                  weight={400}
                  align="center"
                  fontFamily="Arial"
                >
                  {t("DigitalWorkplace.Suggestions.Quote.Speaker")}
                </Text>
              </Column>
              <Column
                width="19%"
                widthTablet="19%"
                widthMobile="40%"
                marginTopTablet="20px"
              >
                <Image src={VivekKundra} />
              </Column>
            </Row>
          </Wrapper>
        </Container>
      </OverLay>
    </Section>
  );
};

export default Quote;
