import React from "react";
import { useTranslation } from "react-i18next";
import LngChangerDropdown from "../Components/Organisms/LngChanger/LngChangerDrowdown";
import Section from "../Components/Atoms/Section";
import Row from "../Components/Atoms/Row";
import Column from "../Components/Atoms/Column";
import backgroundImage from "../Assets/Footer/Footer_Bg.png";
import Text from "../Components/Atoms/Text";
import NavLink from "../Components/Atoms/NavLink";
import styled from "styled-components";
import { device } from "../Components/Atoms/Devices";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Section
      backgroundSize="cover"
      pt="250px"
      ptTablet="100px"
      ptMobile="70px"
      pb="40px"
      backgroundImage={backgroundImage}
    >
      <Row
        justify="center"
        alignItems="center"
        flexDirection="row"
        widthTablet="90%"
        pbTablet="50px"
      >
        <Column width="20%">
          <NavLink to="/Legal" color="#ffffff">
            <Text
              size="16px"
              weight="bolder"
              align="left"
              marginBottom="10px"
              color="inherit"
            >
              {t("Footer.LegalNotice")}
            </Text>
          </NavLink>
        </Column>
        <Column width="20%" widthTablet="90%" widthMobile="90%">
          <Text size="16px" weight="bolder" align="left">
            {t("Footer.Address")}
          </Text>
          <Text size="16px" align="left">
            {t("Footer.Name")}
          </Text>
          <Text size="16px" align="left">
            {t("Footer.Street")}
          </Text>
          <Text size="16px" align="left">
            {t("Footer.City")}
          </Text>
        </Column>
        <Column width="20%" widthTablet="90%" widthMobile="90%">
          <Text size="16px" weight="bolder" align="left">
            {t("Footer.Language")}
          </Text>
          <LngChangerDropdown />
        </Column>
      </Row>
      <Row justify="flex-end">
        <Column width="20%" widthTablet="90%" widthMobile="90%">
          <Text size="14px" align="left">
            © 2022 TecFox GmbH
          </Text>
        </Column>
      </Row>
    </Section>
  );
};

export default Footer;
