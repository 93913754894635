import { useTranslation } from "react-i18next";
import ImageBackground from "../../Assets/DigitalWorkplace/Clouds9.png";
import Intro from "../../Components/Molecules/Intro";
import Suggestions from "./Suggestions";
import Description from "./Description";
import Quote from "./Quote";

const DigitalWorkplace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={ImageBackground}
        title={t("DigitalWorkplace.Title")}
        sizeDesktop="55px"
        sizeTablet="30px"
        sizeMobile="17px"
        mobileTop="60%"
        border
      />
      <Description />
      <Quote />
      <Suggestions />
    </>
  );
};

export default DigitalWorkplace;
