import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Image from "../Atoms/Image";
import { device } from "../Atoms/Devices";

const CardLabel = styled.p`
  font-size: 18px;
  font-weight: 600;
  width: 70%;
  word-wrap: break-word;
  height: 40px;
  margin: auto;
  margin-top: 10px;
  @media ${device.tablet} {
    font-size: 18px;
    height: 20px;
    margin-top: 10px;
  }
  @media ${device.mobile} {
    font-size: 17px;
  }
`;

const CardLink = styled.p`
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
  color: #12a89e;
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const ImageContainer = styled.div`
  padding: 7px;
  transition: 500ms all;
  @media ${device.tablet} {
    padding: 40px;
  }
  @media ${device.mobile} {
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  margin-left: 10px;
  width: 300px;
  background: white;
  border: 1px solid #fff;
  padding-bottom: 25px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 7px 6px -2px rgba(212, 212, 212, 0.75);
  -webkit-box-shadow: 0px 7px 6px -2px rgba(212, 212, 212, 0.75);
  -moz-box-shadow: 0px 7px 6px -2px rgba(212, 212, 212, 0.75);
  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 40px;
    margin-left: 0px;
  }

  &:hover ${ImageContainer} {
    padding: 0;
  }
`;

const Card = (props) => {
  const history = useHistory();

  return (
    <Wrapper onClick={() => history.push(props.to)}>
      <ImageContainer>
        <Image src={props.image} />
      </ImageContainer>
      <CardLabel>{props.title}</CardLabel>
      <CardLink>{props.link}</CardLink>
    </Wrapper>
  );
};

export default Card;
