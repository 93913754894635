import { useTranslation } from "react-i18next";

import Text from "../../Components/Atoms/Text";
import Section from "../../Components/Atoms/Section";
import List from "../../Components/Molecules/List";

const Description = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        pt="120px"
        pb="120px"
        margin="auto"
        pbMobile="50px"
        ptMobile="50px"
        widthMobile="80%"
        widthTablet="80%"
        width="60%"
      >
        <Text
          marginTop="20px"
          size="22px"
          sizeTablet="20px"
          sizeMobile="17px"
          align="left"
          color="#000000"
        >
          {t("ITConsulting.Description.Text1")}
        </Text>
        <Text
          marginTop="80px"
          size="20px"
          sizeTablet="17px"
          sizeMobile="17px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("ITConsulting.Description.Text2")}
        </Text>
        <List
          list={[
            t("ITConsulting.Description.List1"),
            t("ITConsulting.Description.List2"),
            t("ITConsulting.Description.List3"),
            t("ITConsulting.Description.List4"),
            t("ITConsulting.Description.List5"),
            t("ITConsulting.Description.List6"),
            t("ITConsulting.Description.List7"),
          ]}
          left="0px"
          sizeMobile="15px"
        />
      </Section>
    </>
  );
};

export default Description;
