import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ButtonLabel } from "../../Components/Atoms/Button";
import NavLink from "../../Components/Atoms/NavLink";
import Text from "../../Components/Atoms/Text";
import Section from "../../Components/Atoms/Section";
import { HiArrowNarrowRight } from "react-icons/hi";

const Description = () => {
  const { t } = useTranslation();

  const ReadMoreArrow = styled(HiArrowNarrowRight)`
    color: ${(props) => props.theme.colors.primary};
    margin-left: 5px;
    font-size: 20px;
  `;

  const ReadMoreContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 15px;
  `;

  return (
    <>
      <Section
        pt="120px"
        pb="50px"
        margin="auto"
        pbMobile="50px"
        ptMobile="50px"
        widthMobile="80%"
        widthTablet="80%"
        width="60%"
      >
        <Text
          marginTop="20px"
          size="22px"
          sizeTablet="20px"
          sizeMobile="17px"
          align="left"
          color="#000000"
        >
          {t("AppDevelopment.Description.Text1")}
        </Text>
        <ReadMoreContainer>
          <NavLink to="/Contact" padding="0">
            <ButtonLabel
              fontSize="20px"
              fontSizeTablet="17px"
              fontSizeMobile="15px"
            >
              {t("General.Counselling")}
            </ButtonLabel>
            <ReadMoreArrow />
          </NavLink>
        </ReadMoreContainer>
      </Section>
    </>
  );
};

export default Description;
