import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { HiArrowNarrowRight } from "react-icons/hi";

import M365 from "../../Assets/DigitalWorkplace/M365.png";
import Teams from "../../Assets/DigitalWorkplace/Teams.png";
import GCP from "../../Assets/DigitalWorkplace/GCP.png";

import Section from "../../Components/Atoms/Section";
import Text from "../../Components/Atoms/Text";
import Row from "../../Components/Atoms/Row";
import Column from "../../Components/Atoms/Column";
import Image from "../../Components/Atoms/Image";
import Divider from "../../Components/Atoms/Divider";
import List from "../../Components/Molecules/List";
import { ButtonLabel, Link } from "../../Components/Atoms/Button";
import NavLink from "../../Components/Atoms/NavLink";
import Carousel from "../../Components/Organisms/Carousel/Carousel";
import Title from "../../Components/Atoms/Title";

const Suggestions = () => {
  const { t } = useTranslation();

  const ReadMoreArrow = styled(HiArrowNarrowRight)`
    color: ${(props) => props.theme.colors.primary};
    margin-left: 5px;
    font-size: 20px;
  `;

  const listData1 = [
    t("DigitalWorkplace.Suggestions.M365.List1"),
    t("DigitalWorkplace.Suggestions.M365.List2"),
    t("DigitalWorkplace.Suggestions.M365.List3"),
    t("DigitalWorkplace.Suggestions.M365.List4"),
    t("DigitalWorkplace.Suggestions.M365.List5"),
    t("DigitalWorkplace.Suggestions.M365.List6"),
    t("DigitalWorkplace.Suggestions.M365.List7"),
  ];

  const listData2 = [
    t("DigitalWorkplace.Suggestions.GCP.List1"),
    t("DigitalWorkplace.Suggestions.GCP.List2"),
    t("DigitalWorkplace.Suggestions.GCP.List3"),
    t("DigitalWorkplace.Suggestions.GCP.List4"),
    t("DigitalWorkplace.Suggestions.GCP.List5"),
    t("DigitalWorkplace.Suggestions.GCP.List6"),
  ];

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
    1440: { items: 1 },
    1920: { items: 1 },
  };

  const ReadMoreContainer1 = styled.div`
    position: absolute;
    bottom: 20px;
    right: 15px;
  `;

  const ReadMoreContainer2 = styled.div`
    position: absolute;
    bottom: 0px;
    right: 15px;
  `;

  const items = [
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="22px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("DigitalWorkplace.Suggestions.M365.Title")}
      </Text>
      <Divider width="97%" height="2px" />
      <Column
        width="60%"
        marginTop="40px"
        widthTablet="90%"
        widthMobile="90%"
        marginBottomTablet="20px"
        marginTopTablet="20px"
        marginTopMobile="10px"
      >
        <Image src={M365} />
      </Column>
      <Column
        width="35%"
        marginTop="50px"
        marginBottom="80px"
        marginBottomTablet="80px"
        marginBottomMobile="60px"
      >
        <List listStyleType="square" list={listData1} left="0px" />
      </Column>
      <ReadMoreContainer1>
        <Link
          href="https://www.microsoft.com/de-ch/microsoft-365"
          target="_blanc"
        >
          <ButtonLabel fontSize="18px">{t("General.More")}</ButtonLabel>
          <ReadMoreArrow />
        </Link>
      </ReadMoreContainer1>
      <ReadMoreContainer2>
        <NavLink to="/Contact" padding="0">
          <ButtonLabel fontSize="18px">{t("General.Counselling")}</ButtonLabel>
          <ReadMoreArrow />
        </NavLink>
      </ReadMoreContainer2>
    </Row>,
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="20px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("DigitalWorkplace.Suggestions.Swisscom.Title")}
      </Text>
      <Divider width="97%" />
      <Column
        width="60%"
        marginTop="40px"
        marginBottom="40px"
        widthTablet="90%"
        widthMobile="90%"
        marginBottomTablet="20px"
        marginTopTablet="20px"
        marginTopMobile="10px"
      >
        <Image src={Teams} />
      </Column>
      <Column
        width="32%"
        marginTop="60px"
        marginBottomTablet="80px"
        marginBottomMobile="60px"
      >
        <Text
          color="#000000"
          align="left"
          size="18px"
          lineHeight="33px"
          lineHeightTablet="25px"
          sizeTablet="15px"
          sizeMobile="13px"
          lineHeightMobile="18px"
        >
          {t("DigitalWorkplace.Suggestions.Swisscom.Description")}
        </Text>
      </Column>
      <ReadMoreContainer1>
        <Link
          href="https://www.swisscom.ch/de/business/enterprise/angebot/new-work/microsoft-cloud-services/microsoft-teams-telefonie.html"
          target="_blanc"
        >
          <ButtonLabel fontSize="18px">{t("General.More")}</ButtonLabel>
          <ReadMoreArrow />
        </Link>
      </ReadMoreContainer1>
      <ReadMoreContainer2>
        <NavLink to="/Contact" padding="0">
          <ButtonLabel fontSize="18px">{t("General.Counselling")}</ButtonLabel>
          <ReadMoreArrow />
        </NavLink>
      </ReadMoreContainer2>
    </Row>,
    <Row marginTop="40px" marginRightMobile="10px">
      <Text
        size="30px"
        sizeTablet="22px"
        sizeMobile="17px"
        marginLeft="15px"
        color="#000000"
        transform="uppercase"
        align="left"
      >
        {t("DigitalWorkplace.Suggestions.GCP.Title")}
      </Text>
      <Divider width="97%" />
      <Column
        width="50%"
        marginTop="40px"
        marginBottom="40px"
        widthTablet="90%"
        widthMobile="90%"
        marginBottomTablet="10px"
        marginBottomMobile="10px"
        marginTopTablet="0px"
      >
        <Image src={GCP} />
      </Column>
      <Column
        width="42%"
        marginTop="60px"
        marginBottomTablet="60px"
        marginBottomMobile="60px"
      >
        <List listStyleType="square" list={listData2} left="0px" />
      </Column>
      <ReadMoreContainer1>
        <Link
          href="https://cloud.google.com/products/calculator?hl=de"
          target="_blanc"
        >
          <ButtonLabel fontSize="18px">
            {t("DigitalWorkplace.Suggestions.GCP.PriceCalc")}
          </ButtonLabel>
          <ReadMoreArrow />
        </Link>
      </ReadMoreContainer1>
      <ReadMoreContainer2>
        <NavLink to="/Contact" padding="0">
          <ButtonLabel fontSize="18px">{t("General.Counselling")}</ButtonLabel>
          <ReadMoreArrow />
        </NavLink>
      </ReadMoreContainer2>
    </Row>,
  ];

  return (
    <Section
      pt="150px"
      margin="auto"
      pbMobile="50px"
      ptMobile="50px"
      widthMobile="90%"
    >
      <Title>{t("DigitalWorkplace.Suggestions.Title")}</Title>
      <Section width="80%" widthTablet="80%" margin="auto" pt="30px" pb="100px">
        <Carousel items={items} responsive={responsive} />
      </Section>
    </Section>
  );
};

export default Suggestions;
