import { useTranslation } from "react-i18next";

import Text from "../../Components/Atoms/Text";
import Section from "../../Components/Atoms/Section";

const Description = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        pt="120px"
        pb="150px"
        margin="auto"
        pbMobile="50px"
        ptMobile="50px"
        widthMobile="80%"
        widthTablet="80%"
        width="60%"
      >
        <Text
          marginTop="20px"
          size="22px"
          sizeTablet="20px"
          sizeMobile="17px"
          align="left"
          color="#000000"
          weight={600}
        >
          {t("DigitalWorkplace.Description.Text1")}
        </Text>
        <Text
          marginTop="20px"
          size="18px"
          sizeTablet="16px"
          sizeMobile="15px"
          align="left"
          color="#000000"
        >
          {t("DigitalWorkplace.Description.Text2")}
        </Text>
        <Text
          marginTop="20px"
          size="18px"
          sizeTablet="16px"
          sizeMobile="15px"
          align="left"
          color="#000000"
        >
          {t("DigitalWorkplace.Description.Text3")}
        </Text>
        <Text
          marginTop="20px"
          size="18px"
          sizeTablet="16px"
          sizeMobile="15px"
          align="left"
          color="#000000"
        >
          {t("DigitalWorkplace.Description.Text4")}
        </Text>
      </Section>
    </>
  );
};

export default Description;

/*


  const listDataCloudTrans = [
    t("DigitalWorkplace.Description.CloudTrans.List1"),
    t("DigitalWorkplace.Description.CloudTrans.List2"),
    t("DigitalWorkplace.Description.CloudTrans.List3"),
  ];

  const listDataSetup = [
    t("DigitalWorkplace.Description.CloudTrans.List1"),
    t("DigitalWorkplace.Description.CloudTrans.List2"),
    t("DigitalWorkplace.Description.CloudTrans.List3"),
  ];

 <Row>
          <Column width="45%">
            <Title marginBottom="0px">
              {t("DigitalWorkplace.Description.CloudTrans.Title")}
            </Title>
            <Divider width="100%" marginBottom="50px" />
            <Row>
              <Column width="40%">
                <Image src={Server} />
              </Column>
              <Column width="58%">
                <List
                  listStyleType="square"
                  list={listDataCloudTrans}
                  left="0px"
                />
              </Column>
            </Row>

            <Text
              marginTop="30px"
              size="22px"
              sizeTablet="20px"
              sizeMobile="20px"
              align="left"
              color="#000000"
              weight={600}
            >
              {t("DigitalWorkplace.Description.CloudTrans.Text1")}
            </Text>
            <Text
              marginTop="20px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
            >
              {t("DigitalWorkplace.Description.CloudTrans.Text2")}
            </Text>
            <Text
              marginTop="20px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
            >
              {t("DigitalWorkplace.Description.CloudTrans.Text3")}
            </Text>
          </Column>
          <Column width="45%">
            <Title marginBottom="0px">
              {t("DigitalWorkplace.Description.Setup.Title")}
            </Title>
            <Divider width="100%" />
            <List listStyleType="square" list={listDataSetup} left="0px" />
            <Text
              marginTop="10px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
            >
              {t("DigitalWorkplace.Description.Text1")}
            </Text>
            <Text
              marginTop="60px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
            >
              {t("DigitalWorkplace.Description.Text2")}
            </Text>
            <Text
              marginTop="20px"
              size="18px"
              sizeTablet="16px"
              sizeMobile="16px"
              align="left"
              color="#000000"
            >
              {t("DigitalWorkplace.Description.Text3")}
            </Text>
          </Column>
        </Row>
        */
