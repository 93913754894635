import { useTranslation } from "react-i18next";

import Intro from "../../Components/Molecules/Intro";
import ImageBackground from "../../Assets/Contact/Header.png";
import ContactForm from "./ContactForm";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={ImageBackground}
        title={t("ContactPage.Title")}
        mobileTop="65%"
        sizeDesktop="65px"
        sizeTablet="40px"
        sizeMobile="25px"
        border
      />
      <ContactForm />
    </>
  );
};

export default Contact;
