import ImageBackground from "../../Assets/AppDevelopment/Header.png";
import { useTranslation } from "react-i18next";

import Intro from "../../Components/Molecules/Intro";
import Description from "./Description";
import Competences from "./Competences";

const AppDevelopment = () => {
  const { t } = useTranslation();

  return (
    <>
      <Intro
        backgroundImage={ImageBackground}
        title={t("AppDevelopment.Title")}
        sizeDesktop="55px"
        sizeTablet="30px"
        sizeMobile="17px"
        border
        mobileTop="60%"
      />
      <Description />
      <Competences />
    </>
  );
};

export default AppDevelopment;
