import styled from "styled-components";

const Divider = styled.div`
  width: ${(props) => props.width || "80%"};
  margin: auto;
  background-color: ${(props) => props.color || props.theme.colors.primary};
  height: ${(props) => props.height || "3px"};
  margin-bottom: ${(props) => props.marginBottom || "10px"};
`;

export default Divider;
