import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Card from "../../Components/Molecules/Card";
import { device } from "../../Components/Atoms/Devices";

import Image1 from "../../Assets/Home/Service1.png";
import Image3 from "../../Assets/Home/Service3.png";
import Image4 from "../../Assets/Home/Service4.png";
import Title from "../../Components/Atoms/Title";
import Section from "../../Components/Atoms/Section";

export const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const ServicesContainer = styled.div`
  padding: 0.5rem calc((100vw - 1000px) / 2);
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.tablet} {
    width: 60%;
  }
  @media ${device.mobile} {
    width: 80%;
  }
`;

const Services = () => {
  const { t } = useTranslation();
  return (
    <Section
      pt="200px"
      pb="200px"
      ptTablet="100px"
      pbTablet="50px"
      ptMobile="50px"
      pbMobile="50px"
      margin="auto"
    >
      <ServicesContainer>
        <Container>
          <Title border>{t("Home.Services.Title")}</Title>
          <ItemsContainer>
            <Card
              title={t("Home.Services.ServicesLabel1")}
              image={Image1}
              to="/AppDevelopment"
            />
            <Card
              title={t("Home.Services.ServicesLabel2")}
              image={Image3}
              to="/DigitalWorkplace"
            />
            <Card
              title={t("Home.Services.ServicesLabel3")}
              image={Image4}
              to="/ITConsulting"
            />
          </ItemsContainer>
        </Container>
      </ServicesContainer>
    </Section>
  );
};

export default Services;
