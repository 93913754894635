import styled from "styled-components";
import { device } from "./Devices";

const HeroBox = styled.div`
  width: 90%;
  margin: auto;
  position: absolute;
  top: ${(props) => props.top || "50%"};
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${device.tablet} {
    left: 50%;
    top: ${(props) => props.tabletTop || "50%"};
    width: 60%;
  }
  @media ${device.mobile} {
    left: 50%;
    top: ${(props) => props.mobileTop || "50%"};
    width: 90%;
  }
`;

export default HeroBox;
